import React from 'react'
import { Flex, Box, Text, Icon, Button, Link, Heading, useTheme } from '@pw/ui'
import Img from 'gatsby-image'

import PostShare from './Share'

import PostMeta from './Meta'
import './fragments'

const PostList = ({ posts, location }) => {
  const theme = useTheme()

  return posts.map(({ node: post }, i) => {
    const fluidImage = post?.heroImage?.fluid || false

    return (
      <Box mb={{ base: 4, md: 16 }} key={`category:${i}`}>
        <Box as="header" mb="4" px={{ base: '2', lg: '0' }}>
          <Heading
            as="h2"
            fontSize={{ base: '2xl', md: '4-5xl' }}
            color="green.700"
            lineHeight="1.1"
            textTransform="capitalize"
          >
            <Link to={`/${post.slug}`}>{post.title}</Link>
          </Heading>

          <PostMeta pl="1" mt="2" post={post} />
        </Box>

        {fluidImage && (
          <Link
            to={`/${post.slug}`}
            d="block"
            rounded="sm"
            borderWidth="1px"
            borderColor="darkBeige"
            boxShadow={theme.shadows.sm}
            p="2"
          >
            <Img as={Img} fluid={fluidImage} />
          </Link>
        )}

        <Box py={{ base: 2, md: 4 }} px={{ base: '2', lg: '0' }}>
          <Text>{post?.description?.description || ''}</Text>
        </Box>

        <Flex
          as="footer"
          py="4"
          pb="6"
          my="1"
          px={{ base: '2', lg: '0' }}
          alignItems="center"
          justifyContent="space-between"
          borderBottomWidth="1px"
          borderColor="gray.50"
        >
          <Box>
            <PostShare post={post} location={location} />
          </Box>

          <Box>
            <Button
              _hover={{ color: 'greenHoverColor' }}
              color="greenColor"
              variant="link"
              to={`/${post.slug}`}
              textTransform="uppercase"
              fontFamily="heading"
            >
              Read More <Icon name="chevron-right" size="20px" />
            </Button>
          </Box>
        </Flex>
      </Box>
    )
  })
}

export default PostList
