import React from 'react'
import { graphql } from 'gatsby'
import { Box } from '@pw/ui'
import get from '@pw-utils/get'

import { Layout, Container } from '../components/Layout'
import Sidebar from '../components/Sidebar'
import Pagination from '../components/Pagination'
import PostList from '../components/Posts/List'

export const PostsPageTemplate = ({ data, pageContext, location }) => {
  const posts = get(data, 'allContentfulBlogPost.edges', [])

  return (
    <Layout
      location={location}
      headerTitle="Blog Posts"
      seo={{
        title: `Wellness Travel Blog Posts page ${pageContext?.currentPage || 1}`,
        description: `Wellness Travel Blog Posts page ${pageContext?.currentPage || 1}`,
      }}
    >
      <Container
        d="flex"
        pt="10"
        pb="40"
        px={{ base: 0, md: 4 }}
        maxW={{ md: '1200px', lg: '1400px' }}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Box flex="3" pr={{ base: 5, lg: '20' }} pl={{ base: 5, md: 0 }}>
          <PostList posts={posts} />

          <Pagination
            my="4"
            baseLink={`/blog/page/`}
            currentPage={pageContext.currentPage}
            totalPages={pageContext.totalPages}
          />
        </Box>

        <Box as="aside" flex="1" w="100%" maxWidth={{ md: '300px' }}>
          <Sidebar />
        </Box>
      </Container>
    </Layout>
  )
}

export default PostsPageTemplate

export const pageQuery = graphql`
  query PostsByPage($slugs: [String!]) {
    allContentfulBlogPost(
      filter: { slug: { in: $slugs } }
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...postFields
          seo {
            ...seoFields
          }
        }
      }
    }
  }
`
