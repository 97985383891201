import React from 'react'
import { Flex, IconButton, Button, Link, Box } from '@pw/ui'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

const Pagination = ({ baseLink = '/', currentPage = 0, totalPages = 0, ...props }) => {
  if (totalPages <= 1) {
    return null
  }

  const getLink = page => `${baseLink}${page}`

  return (
    <Flex
      alignItems="center"
      justifyContent={{ base: 'space-between', md: 'flex-start' }}
      {...props}
    >
      {currentPage > 1 && (
        <IconButton
          as={Link}
          to={getLink(currentPage - 1)}
          icon={FaChevronLeft}
          aria-label="View Previous Page"
          variantColor="green"
          mr="1"
        />
      )}

      <Box display={{ base: 'block', md: 'none' }}>
        Page {currentPage}/{totalPages}
      </Box>

      {Array(totalPages)
        .fill()
        .map((_page, index) => (
          <Button
            isDisabled={currentPage === index + 1}
            to={getLink(index + 1)}
            aria-label={`View Page ${index + 1}`}
            variantColor="green"
            variant={currentPage === index + 1 ? 'solid' : 'outline'}
            mx="1"
            display={{ base: 'none', md: 'inherit' }}
          >
            {index + 1}
          </Button>
        ))}

      {currentPage < totalPages && (
        <IconButton
          as={Link}
          to={getLink(currentPage + 1)}
          icon={FaChevronRight}
          aria-label="View Next Page"
          variantColor="green"
          ml="1"
        />
      )}
    </Flex>
  )
}

export default Pagination
